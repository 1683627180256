@import url('https://fonts.googleapis.com/css?family=Assistant:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

html,
body,
#app {
  margin: 0 auto;
  width: 100%;
  max-width: 768px;
  min-height: 100vh;
  height: 100%;
  background-color: #F8F9FA;
  box-shadow: 0px 0px 0px 1px #eaf1f1;
}

/* Override Grommet's default button focus styling */
button {
  outline: none !important;
  box-shadow: none !important;
}

body {
  font-family: 'Source Sans Pro', 'Assistant', 'Manrope', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
