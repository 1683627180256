/* Global Override - default color dark teal 
TODO: Move this to global file
*/ 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: rgb(20, 49, 58) !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgb(20, 49, 58) !important;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
}

/* Unique Override - override color to white */
.altInputFill input:-webkit-autofill,
.altInputFill input:-webkit-autofill:hover,
.altInputFill input:-webkit-autofill:focus,
.altInputFill input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #F4F0E9 !important;
}

.altInputFill input:-webkit-autofill {
  -webkit-text-fill-color: #F4F0E9 !important;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
}
