@import url(https://fonts.googleapis.com/css?family=Assistant:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
html,
body,
#app {
  margin: 0 auto;
  width: 100%;
  max-width: 768px;
  min-height: 100vh;
  height: 100%;
  background-color: #F8F9FA;
  box-shadow: 0px 0px 0px 1px #eaf1f1;
}

/* Override Grommet's default button focus styling */
button {
  outline: none !important;
  box-shadow: none !important;
}

body {
  font-family: 'Source Sans Pro', 'Assistant', 'Manrope', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.indiana-dragging.indiana-dragging-override {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

#guarantee ol {
  -webkit-padding-start: 16px;
          padding-inline-start: 16px;
}

/* Global Override - default color dark teal 
TODO: Move this to global file
*/ 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: rgb(20, 49, 58) !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgb(20, 49, 58) !important;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
}

/* Unique Override - override color to white */
.altInputFill input:-webkit-autofill,
.altInputFill input:-webkit-autofill:hover,
.altInputFill input:-webkit-autofill:focus,
.altInputFill input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #F4F0E9 !important;
}

.altInputFill input:-webkit-autofill {
  -webkit-text-fill-color: #F4F0E9 !important;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
}

#guarantee ol {
  -webkit-padding-start: 16px;
          padding-inline-start: 16px;
}

.hosted-field--label {
  font-size: 1.125em;
  line-height: 32px;
  transition: all 0.15s ease-out;
  width: auto;
  font-weight: 400;
  background-color: #F8F9FA;
  padding: 0px 10px;
  flex-wrap: nowrap;
  overflow: hidden;
  display: inline-flex;
  margin-bottom: 0px;
  color: #14313a;
}

.hosted-field--label.invalid {
  color: red;
}

.hosted-field--label.focused {
  color: #836A25;
}

span.icon {
  position: relative;
  top: 0.2em;
  margin-right: 0.2em;
  /* padding-left: 10px; */
}

.hosted-field {
  height: 22px;
  display: block;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
  padding: 20px 0px 10px 20px;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #14313a;
  line-height: 20px;
  letter-spacing: 0px;
  border: 1px solid rgba(211, 207, 200, 0.4);
  border-radius: 3px;
  width: auto;
  margin-top: -20px;
}
/*--------------------
BT HOSTED FIELDS SPECIFIC 
--------------------*/

.hosted-field.focused {
  border: 1px solid #836A25;
}

.hosted-field.invalid {
  border: 1px solid red;
}

.ppbtn > div > iframe {
  z-index: 1 !important;
}
