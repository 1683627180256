.hosted-field--label {
  font-size: 1.125em;
  line-height: 32px;
  transition: all 0.15s ease-out;
  width: auto;
  font-weight: 400;
  background-color: #F8F9FA;
  padding: 0px 10px;
  flex-wrap: nowrap;
  overflow: hidden;
  display: inline-flex;
  margin-bottom: 0px;
  color: #14313a;
}

.hosted-field--label.invalid {
  color: red;
}

.hosted-field--label.focused {
  color: #836A25;
}

span.icon {
  position: relative;
  top: 0.2em;
  margin-right: 0.2em;
  /* padding-left: 10px; */
}

.hosted-field {
  height: 22px;
  display: block;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
  padding: 20px 0px 10px 20px;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #14313a;
  line-height: 20px;
  letter-spacing: 0px;
  border: 1px solid rgba(211, 207, 200, 0.4);
  border-radius: 3px;
  width: auto;
  margin-top: -20px;
}
/*--------------------
BT HOSTED FIELDS SPECIFIC 
--------------------*/

.hosted-field.focused {
  border: 1px solid #836A25;
}

.hosted-field.invalid {
  border: 1px solid red;
}

.ppbtn > div > iframe {
  z-index: 1 !important;
}